@import "../../../styles/media-queries.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
:root{
    --amber1:#F2994A;
    --red1:#EB5757;
    --green1:#27AE60;
    --accent1:#F7F9FB;
}

.text-disable1{
    color: #6c757d;
}
.text-amber1{
    color: var(--amber1);
}
.text-red1{
    color: var(--red1);
}
.text-green1{
    color: var(--green1);
}

.bg-accent1{
    background-color: var(--accent1) !important;
}

.bg-calls1{
    background-color: #1A4682;
}
.bg-chats1{
    background-color: #2B74D8;
}
.bg-focus1{
    background-color: #74AAF3;
}

.bg-amber1{
    background-color: var(--amber1);
}
.bg-red1{
    background-color: var(--red1);
}
.bg-green1{
    background-color: var(--green1);
}

.bg-avatar1{
    span{
        cursor: default;
        background-color: rgba(#6264a7,.25);
    }
}

.bg-avatar2{
    span{
        transition: all ease-in-out 200ms;
        background-color: rgba(#e8e8e8,.25);
        color: rgba($color: #000000, $alpha: .6);
    }
}

.text-view{
    font-weight: 500;
    margin-top: 8px;
    line-height: 1;
    color:#6264a7;
    border-bottom: solid 2px #6264a7;
}

.team-insights-container {
    *, ::before, ::after {
        font-family: 'Roboto' !important;
    }
}

.manager-side-pan {
    display: block;
    @include gt-sm{
    min-width: 324px;
    }
    padding: 24px 16px;
}

.hero-container{
    overflow: auto;
    padding: 36px;
    justify-content: center;
    background-color: #EFF2F7 !important;
    height: 100%;
    @include lt-sm{
        padding: 12px 8px;
        scrollbar-width: thin;
    }
    @include lt-md{
        padding: 24px 12px;
    }
}

.container-empty-message{
    margin: 0 6px;
    min-height: 75%;
    display: grid;
    place-items: center;
    opacity: .5;
}

.hero-main{
    gap: 36px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(424px, 1fr));
    @include lt-sm{
        grid-template-columns: 1fr;
    }
    
    .card-container:nth-child(6n+1) {
        .container-right{
            background-color: #D0D5EF;
        }
    }
    .card-container:nth-child(6n+2) {
        .container-right{
            background-color: #EFE1D0;
        }
    }
    .card-container:nth-child(6n+3) {
        .container-right{
            background-color: #F7D8EE;
        }
    }
    .card-container:nth-child(6n+4) {
        .container-right{
            background-color: #D0DDEF;
        }
    }
    .card-container:nth-child(6n+5) {
        .container-right{
            background-color: #D0ECEF;
        }
    }
    .card-container:nth-child(6n+6) {
        .container-right{
            background-color: #dfd0ef;
        }
    }
}
.card-container{
    gap: 12px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    background-color: #fff;
    //box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    border-radius: 12px;
    padding: 12px;
    &.side-pan{
        cursor: default;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        &:hover{
            box-shadow: none;
            transform: none;
        }
    }

    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    transition: all 300ms ease-in-out;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
        //transform: scale(1.01);
        //background-color: #f3f4f7;
        transition: all 200ms ease-in-out;
    }
}

.container-left{
    min-width: 220px;
}

.container-right{
    min-width: 222px;
    display: grid;
    gap: 12px;
    padding: 8px 12px 12px 12px;
    //background-color: #D0D5EF;
    border-radius: 4px;
    &.side-pan{
        background-color: transparent;
    }
}

.profile-header{
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
    color:#4f4f4f ;
    line-height: 1.2;
    letter-spacing: .1px;
    transition: all ease-in-out 200ms;
    &:hover:not(.hover-none){
        cursor: pointer;
        color:#1A4682;
        text-shadow: 1px 1px 4px rgba(#1a4682,.25);
        transition: all ease-in-out 200ms;
        .bg-avatar2{
            span{
                transition: all ease-in-out 200ms;
                span{
                    background: transparent;
                }
                background-color: rgba(#6264a7,.25);
            color: rgba($color: #6264a7, $alpha: .6);
            }
        }
    }
    .name-label{
        font-size: 14px;
        font-weight: 500;
    }
    .level-label, .org-email {
        font-size: 9px;
    }

    &.side-pan{
        .team-vibe{
            background-color:#F0F1F6 ;
            margin-bottom: 4px;
            padding: 2px 4px 4px 4px;
            border-radius: 2px;

            .vibe-label{
                text-align: center;
                font-size: 10px;
                color: #828282;
            }
            .vibe-val{
                padding: 4px;
                font-size: 10px;
                font-weight: 600;
                border-radius: 2px;
                &.vibe-pos{
                    color: var(--green1);
                    border: solid 1px var(--green1);
                    background-color: #FFFAE9;
                    text-align: center;
                }
                &.vibe-neu{
                    color: var(--amber1);
                    border: solid 1px var(--amber1);
                    background-color: #FFFAE9;
                    text-align: center;
                }
                &.vibe-neg{
                    color: var(--amber1);
                    border: solid 1px var(--amber1);
                    background-color: #FFFAE9;
                    text-align: center;
                }
            }
        }
    }
}

.sentiments-list-card{
    margin-top: 2px;
    height: 28.73px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 2px;
    border-radius: 2px;
    background-color: rgba(#fff,.75);
    .avatar-wrapper{
        display: flex;
        align-items: center;
        gap: 8px;
        color:#4f4f4f ;
        line-height: 1.2;
        letter-spacing: .1px;
        transition: all ease-in-out 200ms;
        .list-lable{
            font-size: 10px;
            font-weight: 500;
        }
        .list-position-lable {
            font-size: 9px;
        }
    }
    .sentiments-kpi-wrapper{
        padding-left: 2px;
        div{
            font-size: 9px;
            white-space: nowrap;
        }
    }
}


.my-team-container{
    border-radius: 12px;
    gap: 12px;
    padding: 12px;
    margin-bottom: 36px !important;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    transition: all 300ms ease-in-out;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
        transition: all 200ms ease-in-out;
    }

    .min-width300{
        min-width: 300px;
    }

    .my-team{
        padding: 4px 10px 9px 12px;
        .my-team-head{
            margin-bottom: 12px;
            font-size: 13px;
            color: #333333;
            font-weight: 500;
        }
        .my-team-body{
            .myteam-wraps{
                display: flex;
                flex-wrap: wrap;
                gap: 12px;

                .user-container{
                    margin: 4px 8px;
                }

                .username-label{
                    font-size: 10px;
                    color: #333333;
                }
            }
        }
    }
    .team-progress{
        min-width: 300px;
        padding: 4px 10px 9px 12px;
        .team-progress-wraps{
            display: grid;
            height: 100%;
            gap: 10px;
        }
        .team-progress-body{
            display: grid;
            height: 100%;
            gap: 10px;
            .progress-contains{
                .prog-lable-container{
                    display: flex;
                    align-items: center;
                    .prog-title{
                        font-size: 12px;
                        color: #4f4f4f;
                    }
                    .prog-val-total{
                        margin-left: auto;
                        color: #333333;
                        .name-lable{
                            font-size: 14px;
                            font-weight: 500;
                        }
                        .symbol{
                            font-size: 10px;
                        }
                    }
                }
                .prog-bar-container{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 6px;
                    margin: 4px auto;
                    .prog-bars{
                        height: 100%;
                        &:first-child{
                            border-radius: 3px 0 0 3px;
                        }
                        &:last-child{
                            border-radius:0 3px 3px 0;
                        }
                        &:only-child {
                            border-radius:3px;
                        }
                    }
                    .no-data-pb{
                        background-color: #e8e8e8;
                        width: 100% !important;
                    }
                    .calls-meetings{
                        &:first-child{
                            background-color: #2B74D8;
                        }
                        &:nth-child(2){
                            background-color: #74AAF3;
                        }
                    }
                    .chats-messages{
                        &:first-child{
                            background-color: #BB6BD9;
                        }
                        &:nth-child(2){
                            background-color: #DCB4EB;
                        }
                    }
                    .avg-focus{
                        &:first-child{
                            background-color: #34C759;
                        }
                        &:nth-child(2){
                            background-color: #E0E0E0;
                        }
                    }
                }
                .bar-lables-container{
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .bar-ind{
                        width: 24px;
                        height: 4px;
                        border-radius: 2px;
                        &.calls-meetings-internal{
                            background-color: #2B74D8;
                        }
                        &.calls-meetings-external{
                            background-color: #74AAF3;
                        }
                        &.chats-messages-internal{
                            background-color: #BB6BD9;
                        }
                        &.chats-messages-external{
                            background-color: #DCB4EB;
                        }
                    }
                    .bar-lable-name{
                        font-size: 10px;
                        line-height: 1.2;
                        color: #4f4f4f;
                        min-width: 40px;
                    }
                }
            }
        }
    }

    .team-vibe {
        padding: 4px 10px 9px 12px;
        .team-vibe-head{
            margin-bottom: 12px;
            font-size: 13px;
            color: #333333;
            font-weight: 500;
        }
        .team-vibe-body{
            .body-wraps{
                display: grid;
                height: 100%;
                justify-content: center;
            }
            .prog-text {
                .pie-prog-lable{
                    font-size: 22px;
                    font-weight: 500;
                    fill: #333333;
                }
                .pie-prog-symbol{
                    font-size: 14px;
                    fill: #333333;
                }
                .prog-lable {
                    font-size: 9px;
                    fill: #828282;
                }
            }
    
            .vibe-lable{
                font-size: 12px;
                font-weight: 500;
                color: #333333;
            }
            .vibe-trends{
                display: flex;
                gap: 4px;
                align-items: center;
                .trend{
                    &.arrow_up{
                        fill: #6FCF97;
                    }
                    &.arrow_down{
                        fill: #EB5757;
                        transform: rotate(180deg);
                    }
                }
                .trend-lable{
                    font-size: 10px;
                    color: #828282;
                }
            }
        }
    }

    .team-sentiments {
        padding: 4px 10px 9px 12px;
        .team-sentiments-head{
            margin-bottom: 12px;
            font-size: 13px;
            color: #333333;
            font-weight: 500;
        }
        .team-sentiments-body{
            .body-wraps{
                display: grid;
                height: 100%;
                justify-content: center;
            }
            .sentiments-bg{
                position: relative;
                height: 123px;
                width: 108px;
                aspect-ratio: 1;
                background-size: 123px 142px;
                background-repeat: no-repeat;
                background-position: center;
                &.img-internal{
                    background-image: url("./sentiments_internal.png");
                }
                &.img-external{
                    background-image: url("./sentiments_external.png");
                }
            }
            .sentiments-ind-lable {
                position: absolute;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                text-shadow: 0px 4px 4px rgba(#000,.25);
                cursor: default;
                .ind-lable{
                    font-size: 22px;
                    font-weight: 500;
                    color: #fff;
                }
                .ind-symbol{
                    font-size: 14px;
                    color: #fff;
                }
            }
            .int-ext-lable{
                .internal-lable{
                    cursor: pointer;
                    font-size: 12px;
                    color: #828282;
                    &.selection{
                        font-weight: 500;
                        color: #2F80ED;
                    }
                }
            }
        }
    }
}

.collaboration-intelligence {
    padding: 12px 10px 9px 12px;
    .head{
        margin-bottom: 12px;
        font-size: 13px;
        color: #333333;
        font-weight: 500;
    }
    .body{
        .timespent-container{
            display: grid;
            gap: 16px;
        .time-spent{
            gap: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .label-group{
                display: flex;
                align-items: center;
                gap: 8px;
                .vl{
                    display: list-item;
                    list-style: inside;
                    line-height: 1.8;
                    color: transparent;
                    height: 100%;
                    width: 4px;
                    border-radius: 2px;
                }
                .vl-dot{
                    height: 4px;
                    width: 4px;
                    border-radius: 50%;
            }
                .labels{
                    line-height: 1.2;
                    .label1{
                        font-size: 12px;
                        letter-spacing: .2%;
                    }
                    .label2{
                        font-size: 10px;
                        letter-spacing: .2%;
                        background: -webkit-linear-gradient(0deg, #1A4682 0%, #BB6BD9 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    .label3{
                        font-size: 10px;
                        letter-spacing: .2%;
                    }
                }
                .labels::before{
                    position: absolute;
                    background-color: #1A4682;
                    width: 4px;
                    height: 100%;
                    left: 0;
                    top: 0;
                }
            }
            .progress-label{
                    display: flex;
                    align-items:baseline;
                    font-weight: 500;
                    color: #333333;
                    .value{
                        font-size: 14px;
                    }
                    .value-type{
                        font-size: 9px;
                    }
                    .indicates{
                        margin-bottom: auto;
                        height: 6px;
                        width: 6px;
                        border-radius: 50%;
                    }
            }
        }
    }
}
}

.employee-vibe {
    &.side-pan{
        order: 2;
    }
    display: grid;
    gap: 4px;
    color: #4f4f4f;
    height: 118px;
    .c-ev-head{
        font-size: 13px;
        color: #333333;
        font-weight: 500;
    }
    .c-ev-body{
        overflow: auto;
        scrollbar-width: thin;
        .response-container{
            .category-container:last-child{
                border-bottom: none;
                padding-bottom: 0px;
            }
            &.side-pan{
                .category-container{
                    border-bottom: solid #bcbcbc77 1px;
                    .value-container{
                        .val-indicate-container{
                            .val-indicate{
                                background-color: #bcbcbc77;
                            }
                        }
                    }
                }
            }
            .category-container{
                cursor: default;
                gap: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 3px 4px 4px 4px;
                border-bottom: solid #ffffff77 1px;
                .cat-label{
                    overflow: hidden;
                    width: calc(100% - 80px);
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size:12px ;
                    letter-spacing: .2%;
                    line-height: 1;
                }
                .value-container{
                    .val-label{
                        font-size: 10px;
                        letter-spacing: 2%;
                        text-align: right;
                    }
                    .val-indicate-container{
                        display: flex;
                        align-items: center;
                        justify-content: end;
                        gap: 2px;
                        padding: 2px auto;
                        &.val-response-0{
                            .val-indicate:nth-child(1n){
                            background-color: #ffffff77;
                            }
                        }
                        &.val-response-5{
                            .val-indicate:nth-child(1n){
                                background-color: var(--green1);
                             }
                        }
                        &.val-response-4{
                            .val-indicate:not(:first-child){
                                background-color: var(--green1);
                            }
                        }
                        &.val-response-3{
                            .val-indicate:not(:first-child,:nth-child(2)){
                                background-color: var(--amber1);
                            }
                        }
                        &.val-response-2{
                            .val-indicate:not(:first-child){
                                background-color: var(--red1);
                            }
                        }
                        &.val-response-1{
                            .val-indicate:nth-child(1n){
                                background-color: var(--red1);
                             }
                        }
                        .val-indicate{
                            height: 2px;
                            width: 10px;
                            border-radius: 1px;
                            background-color: #ffffff77;
                        }
                    }
                }
            }
    }
    }
}

.sentiments-people {
    &.side-pan{
        order: 1;
        margin-bottom: 24px;
    }
    display: grid;
    gap: 8px;
    color: #4f4f4f;
    .head{
        font-size: 13px;
        color: #333333;
        font-weight: 500;
    }
    .neg-section{
        .neg-label{
            font-size: 10px;
            color: var(--red1);
        }
        .neg-body{
            display: flex;
            gap: 8px;
            padding: 4px;
            background-color: #ffffff77;
            &.side-pan{
                background-color: #EFF2F7;
            }
            &>div{
                background-color: transparent;
                border-radius: 50%;
            }
        }
    }
    
    .other-section{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        .other-container{
            display: grid;
            gap: 8px;
            padding: 2px 4px 4px 4px;
            border-radius: 4px;
            background-color: #ffffff77;
            &.side-pan{
                background-color: #EFF2F7;
            }
            .other-label{
                font-size: 10px;
            }
            .other-value{
                display: flex;
                gap: 2px;
                padding-block: 2px;
                margin-inline: auto;
                .other-val{
                    color: #000;
                    font-size: 12px;
                    margin-bottom: auto;
                    line-height: .6;
                }
            }
        }
    }
}


.user-view-container{
    .card-user-view{
        background-color: #fff;
        border-radius: 12px;
        padding: 12px 18px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        transition: all 300ms ease-in-out;
        
        &:hover {
            box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
            transition: all 200ms ease-in-out;
        }
        .c-m-head{
            margin-bottom: 12px;
            font-size: 13px;
            color: #333333;
            font-weight: 500;
        }
        .c-m-body{
            height: calc(100% - 36px);
            display: grid;
            gap: 4px;
            // .vl-user-sentiments{
            //     height: 152px;
            //     width: 4px;
            //     border-radius: 2px;
            //     background: rgb(39,174,96);
            //     background: linear-gradient(317deg, rgba(39,174,96,1) 0%, rgba(235,87,87,1) 100%);
            // }

            .sentiments-wrap-container{
                @include lt-sm {
                    flex-wrap: wrap;
                    overflow: auto;
                    scrollbar-width: thin;
                }
                display: flex;
                overflow: hidden;
                gap: 12px;
            }
            .user-sentiments-container{
                //display: flex;
                width: 100%;
                //gap: 2px;
                //flex-wrap: wrap;
                scrollbar-width: thin;
                border-radius: 6px;
                overflow: auto;
                padding: 4px;
                -webkit-scrollbar-thumb: rgba(255,255,255,.5) !important;
                &.bg-neg-t{
                    background-color: #FDEDED;
                }
                &.bg-pos-t{
                    background-color: #EFF4FA;
                }
                &.bg-neu-t{
                    background-color: #F6F0EB;
                }
                &.bg-bbc-t{
                    background-color: #F0F1F6;
                }
            }
            .sentiment-type{
                margin-bottom: 5px;
                font-size: 10px;
                font-weight: 500;
                line-height: 1.4;
                &.neg-t{
                    color: #EB5757;
                }
                &.pos-t{
                    color: #1A4682;
                }
                &.neu-t{
                    color: #F2994A;
                }
            }

            .calls-meetings-insight{
                border-bottom: solid 1px rgba(#fff,.75);
                padding: 3px;
                display: flex;
                align-items: center;
                gap: 18px;
                .qty-nmbr{
                    cursor: pointer;
                    font-size: 12px;
                    line-height: 1;
                    min-width: 29px;
                    text-align: center;
                    margin-left: auto;
                    padding: 4px 8px;
                    background-color: #F7F9FB;
                    width: auto;
                    &:hover{
                        background-color: #e5edf4;
                    }
                    &:active{
                        background-color: #c3d3e2;
                    }
                    &::selection{
                        cursor: none;
                    }
                }
                .qty-time{
                    min-width: 72px;
                    text-align: right;
                }
            }
            
        }
    }
}
