.wellbeing-bar {
    height: 18px;
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 0;
    margin: 2px 0 8px 0px;
  }

  .wellbeing-fill {
    height: 100%;
    text-align: center;
    color: white;
    border-radius: 0;
    font-weight: 600;
    font-size: 0.8rem;
  }

.wellbeing-fill.wi-1{
    width: 20%;
}
.wellbeing-fill.wi-2{
    width: 40%;
}
.wellbeing-fill.wi-3{
    width: 60%;
}
.wellbeing-fill.wi-4{
    width: 80%;
}
.wellbeing-fill.wi-5{
    width: 100%;
}

.wellbeing-category {
    width: 100%;
}