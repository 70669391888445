@import "../../../styles/media-queries.scss";

body.dark {

    .bg-light-insight {
        background-color: #1f1f1f;
    }

    .border-b-uinsights {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
    }

    .border-r-uinsights {
        border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
    }

    .insights-crum {
        background-color: #1f1f1f;
    }

    .insights-card {
        background-color: #313131;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -2px;

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.9) 0px 25px 50px -12px;
            transition: box-shadow 200ms ease-in-out;
        }
    }

    .insights-card-header {
        .name-label {
            color: #e2e2e2;
        }

        .designation-label {
            color: #828282;
        }

        .org-email {
            color: #828282;
        }
    }

    .insights-card-body {

        .list-view-indicator {
            border: 1px solid darken($color: #fff, $amount: 25%);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
        }

        .detail-view-indicator {
            border: 1px solid darken($color: #fff, $amount: 25%);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
        }

            .list-view-indicator.Negative, .detail-view-indicator.Negative {
                background-color: #F75E75;
            }

            .list-view-indicator.Positive, .detail-view-indicator.Positive {
                background-color: #00ff88;
            }

            .list-view-indicator.Neutral, .detail-view-indicator.Neutral {
                background-color: #F9EC02;
            }

            .list-view-indicator.disabled, .detail-view-indicator.disabled {
                background-color: #bdbdbd;
            }

        .list-view-label {
            color: darken($color: #fff, $amount: 10%);
            font-weight: 500;
        }

        .detail-view-label {
            color: darken($color: #fff, $amount: 10%);
            font-weight: 500;
        }

            .detail-view-label:hover {
                color: #7f85f5;
            }
    }
}


body.contrast {

    .bg-light-insight {
        background-color: #1f1f1f;
    }

    .border-b-uinsights {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
    }

    .border-r-uinsights {
        border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
    }

    .insights-crum {
        background-color: #1f1f1f;
    }

    .insights-card {
        color: #ffff00 !important;
        background-color: #313131;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -2px;

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.9) 0px 25px 50px -12px;
            transition: box-shadow 200ms ease-in-out;
        }
    }

    .name-label {
        color: #ffff00;
    }

    .designation-label {
        color: #ffff00;
    }

    .org-email {
        color: #ffff00;
    }

    .vibe-indicator {
        .vibe-indicator-text {
            color: #ffff00;
        }
    }

    .insights-card-body {

        .list-view-indicator {
            border: 1px solid darken($color: #fff, $amount: 25%);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
        }

        .detail-view-indicator {
            border: 1px solid darken($color: #fff, $amount: 25%);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
        }

            .list-view-indicator.Negative, .detail-view-indicator.Negative {
                background-color: #F75E75;
            }

            .list-view-indicator.Positive, .detail-view-indicator.Positive {
                background-color: #00ff88;
            }

            .list-view-indicator.Neutral, .detail-view-indicator.Neutral {
                background-color: #F9EC02;
            }

            .list-view-indicator.disabled, .detail-view-indicator.disabled {
                background-color: #bdbdbd;
            }

        .list-view-label {
            color: #ffff00;
            font-weight: 500;
        }

        .detail-view-label {
            color: #ffff00;
            font-weight: 500;
        }

            .detail-view-label:hover {
                color: #ffff00;
            }

        .name-label {
            color: #ffff00;
        }
    }
}


.border-b-uinsights {
    border-bottom: 1px solid #dee2e6 !important;
}

.border-r-uinsights {
    border-right: 1px solid #dee2e6 !important;
}

.bg-light-insight {
    background-color: #f8f9fa;
}



@media (min-width: 1400px) {
    .container-cst {
        max-width: 1392px !important;
    }
}


.insights-body {
    height: 496px;
    overflow: hidden;
    transition: all ease-in 200ms;

    &:hover {
        overflow-y: scroll;
        transition: all ease-out 200ms;
    }

    .justify-even {
        justify-content: space-evenly !important;
    }
}

    .insights-body.h-insights {
        @include lt-sm {
            height: calc(100% - 86px);
        }

        @include gt-sm {
            height: calc(100% - 116px);
        }
    }

    .insights-body.h-user-insights {
        @include lt-sm {
            height: calc(100% - 62px);
        }

        @include gt-sm {
            height: calc(100% - 90px);
        }
    }

.user-insight-meeting-behaviour {
    grid-column: 2/4;
}

@media (max-width:960px) {
    .user-insight-meeting-behaviour {
        grid-column: 1/3;
    }
}

@media (max-width:760px) {
    .user-insight-meeting-behaviour {
        grid-column: 1;
    }
}

.insights-crum {
    position: relative;
    background-color: #f8f9fa;
    z-index: 1;
}

.insights-dropdown-list-cst {
    min-width: 164px;

    li.ui-dropdown__item {
        flex-direction: row-reverse;

        &:hover {
            flex-direction: row-reverse;
        }

        img.ui-image.ui-dropdown__item__image {
            margin-left: 0.75rem;
            margin-right: 0.25rem;
            width: 22px;
            /* height: 10px; */
        }
    }
}

.manager-icon-dropdown {
    position: absolute;
    right: 25px;
    top: 4px;
}

.user-rings {
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    background-color: rgba(91, 95, 199,.18);
    border: solid 1px rgb(91, 95, 199);
    position: absolute;
    border-radius: 50%;
    z-index: 0;
}

.rings-warning {
    background-color: rgba(242, 152, 74, 0.18);
    border: solid 1px #f2994a;
}

.insights-card {
    @include lt-sm {
        padding: 14px;
    }

    &.p-tv-header {
        padding: 10px 14px 12px 14px;
    }

    padding: 16px 20px 14px 20px;
    z-index: 10 !important;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-radius: 10px;
    transition: box-shadow 300ms ease-in-out;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
        transition: box-shadow 200ms ease-in-out;
    }
}

.gap-24 {
    gap: 24px !important;
}

.gap-12 {
    gap: 12px !important;
}

.sentiments-value-border {
    span {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        background-color: #fff;
    }

    &.nv-0 {
        span {
            border: solid 2px var(--light);
        }
    }

    &.pv-9, &.pv-10 {
        span {
            border: solid 2px hsl(134, 61%, 41%);
        }
    }

    &.pv-7, &.pv-8 {
        span {
            border: solid 2px hsl(134, 61%, 51%);
        }
    }

    &.pv-5, &.pv-6 {
        span {
            border: solid 2px hsl(134, 61%, 61%);
        }
    }

    &.pv-3, &.pv-4 {
        span {
            border: solid 2px hsl(134, 61%, 71%);
        }
    }

    &.pv-1, &.pv-2 {
        span {
            border: solid 2px hsl(134, 61%, 81%);
        }
    }

    &.nv--1, &.nv--2 {
        span {
            border: solid 2px #FFDAD8;
        }
    }

    &.nv--3, &.nv--4 {
        span {
            border: solid 2px #FFB8B4;
        }
    }

    &.nv--5, &.nv--6 {
        span {
            border: solid 2px #FF9D97;
        }
    }

    &.nv--7, &.nv--8 {
        span {
            border: solid 2px #FE726A;
        }
    }

    &.nv--9, &.nv--10 {
        span {
            border: solid 2px #EB5757;
        }
    }
}

.insights-card-hover {
    transform: translate(-50%,-50%);
    left: 162.5px;
    top: 75px;
    position: absolute;
    overflow: hidden;

    &:hover {
        transition: width 300ms,height 300ms,box-shadow 2s;
    }
}

.insights-card-container {
    position: relative;
    min-width: 336px;
    // @include lt-sm{
    //     max-width: 336px;
    // }
}

.bg-danger-light {
    color: #dc3545;
    border: solid #dc3545 1px;
    background: #ffdee1;
    transition: all 200ms ease-in-out;

    &:hover {
        color: #fff;
        border: solid #dc3545 1px;
        background-color: #dc3545;
        transition: all 200ms ease-in-out;
    }

    &:active {
        background-color: #dc354689;
        transition: all 200ms ease-in-out;
    }
}


.bg-custome-light {
    color: #ffffff;
    background: #6264a7;
    transition: all 200ms ease-in-out;
    box-shadow: 0px 0.2rem 0.4rem -0.075rem rgb(0, 0, 0, 0.25);

    &:hover {
        box-shadow: 0px 0.1rem 0.25rem -0.075rem rgb(0, 0, 0, 0.25);
        background-color: #585a96;
        transition: all 200ms ease-in-out;
    }

    &:active {
        box-shadow: none;
        background-color: #464775;
        transition: all 200ms ease-in-out;
    }
}

.shadow-bg-filter-header {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    background: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(4px);
    transition: all 200ms ease-in-out;

    &:hover {
        background: rgba(255, 255, 255, 0.65);
        transition: all 200ms ease-in-out;
    }
}

.list-view-width {
    width: 326px;
    height: 149px;
}

.insights-card-header {
    .name-label {
        @include lt-sm {
            font-size: 12px;
            line-height: 1;
        }

        color: #333333;
        font-size: 14px;
        font-weight: bold;
    }

    .designation-label {
        color: #828282;
        font-size: 12px;
    }

    .reports-to-label {
        font-size: 12px;
        font-weight: 600;
    }

    .org-email {
        @include lt-sm {
            line-height: 1;
        }

        color: #828282;
        font-size: 10px;
    }

    .vibe-indicator {
        position: relative;

        .vibe-indicator-emoji {
            position: absolute;
            bottom: 8px;
            left: 1px;
        }

        .vibe-indicator-text {
            line-height: 1.35;
            color: #fff;
            width: 80px;
            height: 20px;
            padding: 2px 4px 2px 2px;
            border-radius: 4px;
        }
    }
}

.insights-card-body {
    margin-top: 8px;
    height: calc(100% - 48px);

    .wi-0 {
        color: var(--secondary) !important;
    }

    .wi-1 {
        color: #EB5757 !important;
        border-color: #EB5757 !important;
        background-color: #FFD7D7 !important;
    }

    .wi-2 {
        color: #F2994A !important;
        border-color: #F2994A !important;
        background-color: #FFEFE1 !important;
    }

    .wi-3 {
        color: #F2C94C !important;
        border-color: #F2C94C !important;
        background-color: #FFF8E4 !important;
    }

    .wi-4 {
        color: #6FCF97 !important;
        border-color: #6FCF97 !important;
        background-color: #E3FFEF !important;
    }

    .wi-5 {
        color: #219653 !important;
        border-color: #219653 !important;
        background-color: #DEFFEC !important;
    }

    .list-view-indicator {
        width: 12px;
        height: 12px;
        border: 1px solid #fff;
        border-radius: 50%;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    .detail-view-indicator {
        width: 10px;
        height: 10px;
        border: 1px solid #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

        .list-view-indicator.Negative, .detail-view-indicator.Negative {
            background-color: #eb5757;
        }

        .list-view-indicator.Positive, .detail-view-indicator.Positive {
            background-color: #27ae60;
        }

        .list-view-indicator.Neutral, .detail-view-indicator.Neutral {
            background-color: #f2994a;
        }

        .list-view-indicator.disabled, .detail-view-indicator.disabled {
            background-color: #bdbdbd;
        }

    .list-view-label {
        font-size: 12px;
        font-weight: 600;
        color: #333333;
    }

    .list-view-label-cst {
        font-size: 12px;
        font-weight: 600;
    }

    .detail-view-label {
        font-size: 12px;
        font-weight: 700;
        color: #333333;
    }

        .detail-view-label:hover {
            color: #6264a7;
            font-weight: 700;
            cursor: pointer;
        }

    .wb-cat-label {
        font-size: 12px;
        font-weight: 500;
    }
}

.assigned-users-label {
    font-size: 12px;
    font-weight: 600;
    color: #6264a7;
    transition: all 200ms ease-in-out;
}

    .assigned-users-label:hover {
        text-decoration: underline;
        color: #6264a7;
        cursor: pointer;
        transition: all 200ms ease-in-out;
    }

.list-view-indicator-margin {
    margin-top: 32px;
    margin-bottom: 20px;
}


// .insights-filter-popup {
//     @include lt-sm{
//         width: 95vw;
//     }
//     @include gt-sm{
//         min-width: 400px;
//     }
// }

.cards-section {
    @include lt-sm {
        justify-items: center;
    }

    display: grid;
    gap: 36px;
    grid-template-columns: repeat( auto-fill, minmax(326px, 1fr));
}
