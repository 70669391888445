@import "./variables";
@import './media-queries.scss';

// #root>div.ui-provider:first-child {
//     min-height: 100vh !important;
// }

body.inTeams {
    background-color: transparent !important;
    background: transparent !important;
}

.ui-provider,
body {
    background: transparent !important;
    background-color: transparent !important;
}

//disable click event in rings
.click-disabled {
    pointer-events: none !important;
}


//hide resizable element on card-widget
.react-resizable-hide>.react-resizable-handle {
    display: none !important;
}

.react-resizable-handle.react-resizable-handle-se {
    display: none !important;
}

.inTeams {
    header {
        display: none;
    }


    #main {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    @include lt-sm {
        padding-top: 15px !important;
    }
}

.ui-button:disabled {
    cursor: not-allowed;
}

.k-animation-container {
    z-index: 1001;
}

.k-datepicker .k-picker-wrap.k-state-focused,
.k-datepicker .k-picker-wrap.k-state-active,
.k-timepicker .k-picker-wrap.k-state-focused,
.k-timepicker .k-picker-wrap.k-state-active,
.k-datetimepicker .k-picker-wrap.k-state-focused,
.k-datetimepicker .k-picker-wrap.k-state-active {
    background-color: transparent;
    color: unset;
    border-color: transparent;
}

.k-dateinput .k-dateinput-wrap,
.k-dateinput .k-picker-wrap,
.k-datepicker .k-dateinput-wrap,
.k-datepicker .k-picker-wrap,
.k-datetimepicker .k-dateinput-wrap,
.k-datetimepicker .k-picker-wrap,
.k-timepicker .k-dateinput-wrap,
.k-timepicker .k-picker-wrap {
    // border-color: rgba(0, 0, 0, 0.08)!important;
    border: none;
    background: none;

    .k-input {
        font-size: 14px;
        color: rgb(37, 36, 35);
        background: rgb(243, 242, 241);
        padding: 0.3571rem 2.7857rem 0.3571rem 0.8571rem;
    }
}

.k-datepicker .k-widget.k-dateinput,
.k-datetimepicker .k-widget.k-dateinput,
.k-timepicker .k-widget.k-dateinput {
    border-bottom-width: 0;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-style: solid;
    border-radius: 0.2143rem 0.2143rem 0.1429rem 0.1429rem;
    border-color: transparent;
    margin-top: 0;
}

.k-datepicker .k-picker-wrap .k-select,
.k-timepicker .k-picker-wrap .k-select {
    box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem;
    border-width: 1px;
    border-style: solid;
    min-width: 2.2857rem;
    opacity: 1 !important;
}

.k-timeselector .k-time-list-container .k-time-separator,
.k-datetime-wrap .k-time-list-container .k-time-separator {
    display: inline-flex;
}

.k-actions .k-button,
.k-actions .k-button-secondary,
.k-action-buttons .k-button,
.k-action-buttons .k-button-secondary,
.k-columnmenu-actions .k-button,
.k-columnmenu-actions .k-button-secondary {
    outline: none;
    padding-left: 1.4286rem;
    padding-right: 1.4286rem;
    font-size: 14px;
}

.k-grid-pager {
    font-size: 0.85rem;
}

body.default {

    .ui-checkbox[aria-checked="true"][aria-disabled="true"]>.ui-checkbox__indicator,
    .ui-checkbox[aria-checked="true"][aria-disabled="true"]>.ui-checkbox__indicator::before {
        border-color: rgb(88, 90, 150);
    }

    .k-dateinput .k-dateinput-wrap .k-input,
    .k-dateinput .k-picker-wrap .k-input,
    .k-datepicker .k-dateinput-wrap .k-input,
    .k-datepicker .k-picker-wrap .k-input,
    .k-datetimepicker .k-dateinput-wrap .k-input,
    .k-datetimepicker .k-picker-wrap .k-input,
    .k-timepicker .k-dateinput-wrap .k-input,
    .k-timepicker .k-picker-wrap .k-input {
        color: rgb(37, 36, 35);
        background: rgb(243, 242, 241);
    }

    .k-datepicker .k-picker-wrap .k-select,
    .k-timepicker .k-picker-wrap .k-select {
        box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem;
        border-color: rgb(225, 223, 221);
        color: rgb(37, 36, 35);
    }

    .k-datepicker .k-picker-wrap:hover .k-select,
    .k-datepicker .k-picker-wrap.k-state-hover .k-select,
    .k-timepicker .k-picker-wrap:hover .k-select,
    .k-timepicker .k-picker-wrap.k-state-hover .k-select,
    .k-datetimepicker .k-picker-wrap:hover .k-select,
    .k-datetimepicker .k-picker-wrap.k-state-hover .k-select {
        color: rgb(70, 71, 117);
        background-color: #fff;

        .k-icon::before {
            color: rgb(70, 71, 117);
        }
    }

    .k-time-header .k-time-now:hover,
    .k-time-header .k-time-now:focus {
        color: rgba(70, 71, 117, 1);
    }

    .k-timeselector .k-time-header .k-time-now:hover,
    .k-timeselector .k-time-header .k-time-now:focus,
    .k-datetime-wrap .k-time-header .k-time-now:hover,
    .k-datetime-wrap .k-time-header .k-time-now:focus {
        color: rgba(70, 71, 117, 1);
    }

    .k-actions .k-button:not(.k-primary),
    .k-actions .k-button-secondary,
    .k-action-buttons .k-button:not(.k-primary),
    .k-action-buttons .k-button-secondary,
    .k-columnmenu-actions .k-button:not(.k-primary),
    .k-columnmenu-actions .k-button-secondary {
        border-color: rgb(225, 223, 221);
        box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem;
        background-color: #fff;
        color: rgb(37, 36, 35);
    }

    .k-actions .k-button.k-primary,
    .k-actions .k-button-primary,
    .k-action-buttons .k-button.k-primary,
    .k-action-buttons .k-button-primary,
    .k-columnmenu-actions .k-button.k-primary,
    .k-columnmenu-actions .k-button-primary {
        border-color: rgb(88, 90, 150);
        box-shadow: rgb(0 0 0 / 25%) 0px 0.2rem 0.4rem -0.075rem;
        background-color: rgb(98, 100, 167);
        color: #fff;
    }

    .k-action-buttons .k-button:hover,
    .k-action-buttons .k-button.k-state-hover {
        border-color: rgb(200, 198, 196);
        background-color: rgb(237, 235, 233);

    }

    .k-button.k-primary:hover {
        border-color: rgb(88, 90, 150);
        background-color: rgb(88, 90, 150);
    }

    .k-button:hover,
    .k-button:focus {
        text-decoration: none;
        outline: 0;
    }
}

body.dark {

    .ui-checkbox[aria-checked="true"][aria-disabled="true"]>.ui-checkbox__indicator,
    .ui-checkbox[aria-checked="true"][aria-disabled="true"]>.ui-checkbox__indicator::before {
        border-color: rgb(139, 140, 199);
    }

    .timePicker input[type="time"],
    .datePicker input[type="date"] {
        &::-webkit-calendar-picker-indicator {
            filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
        }
    }

    .k-grid-pager {
        border-color: rgba(0, 0, 0, 0.14);
        color: #fff;
        background-color: #3b3a39;

        .k-pager-refresh,
        .k-pager-nav.k-link,
        .k-pager-numbers .k-link {
            border-color: rgba(0, 0, 0, 0.15);
            color: #FFF;
            background-color: rgba(0, 0, 0, 0.15);
        }

        .k-pager-nav.k-link:hover,
        .k-pager-refresh:hover,
        .k-state-hover.k-pager-nav.k-link,
        .k-state-hover.k-pager-refresh,
        .k-pager-numbers .k-link:hover,
        .k-pager-numbers .k-link.k-state-hover,
        .k-pager-numbers .k-link.k-state-selected {
            border-color: rgba(0, 0, 0, 0.35);
            /* color: #FFF; */
            background-color: rgba(0, 0, 0, 0.35);
        }

    }

    .k-dateinput .k-dateinput-wrap .k-input,
    .k-dateinput .k-picker-wrap .k-input,
    .k-datepicker .k-dateinput-wrap .k-input,
    .k-datepicker .k-picker-wrap .k-input,
    .k-datetimepicker .k-dateinput-wrap .k-input,
    .k-datetimepicker .k-picker-wrap .k-input,
    .k-timepicker .k-dateinput-wrap .k-input,
    .k-timepicker .k-picker-wrap .k-input {
        color: #FFF;
        background: rgb(32, 31, 31);
    }

    .k-datepicker .k-picker-wrap .k-select,
    .k-timepicker .k-picker-wrap .k-select {
        box-shadow: rgb(0 0 0 / 25%) 0px 0.2rem 0.4rem -0.075rem;
        border-color: rgb(96, 94, 92);
        background-color: rgb(45, 44, 44);
        color: #FFF;
    }

    .k-datepicker .k-picker-wrap:hover .k-select,
    .k-datepicker .k-picker-wrap.k-state-hover .k-select,
    .k-timepicker .k-picker-wrap:hover .k-select,
    .k-timepicker .k-picker-wrap.k-state-hover .k-select,
    .k-datetimepicker .k-picker-wrap:hover .k-select,
    .k-datetimepicker .k-picker-wrap.k-state-hover .k-select {
        color: rgb(70, 71, 117);
        background-color: rgb(45, 44, 44);

        .k-icon::before {
            color: rgb(70, 71, 117);
        }
    }

    .k-popup {
        border-color: rgb(17, 16, 15);
        color: #FFF;
        background-color: rgb(45, 44, 44);
        box-shadow: rgb(0 0 0 / 25%) 0px 0.8rem 1rem -0.2rem;

        .k-calendar {
            border-color: transparent;
            color: #fff;
            background-color: transparent;

            .k-calendar-navigation-highlight {
                border-color: #dee2e6;
                color: #FFF;
                background-color: rgb(45, 44, 44);
            }

            .k-calendar-navigation {
                color: #FFF;
                background-color: transparent;
                box-shadow: inset -1px 0 #dee2e6;
            }

            .k-content {
                border-color: #dee2e6;
                color: #FFF;
                background-color: transparent;
            }

            .k-content th,
            .k-calendar-content th,
            .k-content .k-calendar-th,
            .k-calendar-content .k-calendar-th {
                color: rgba(255, 255, 255, 0.87);
            }
        }

        .k-timeselector {

            .k-time-header .k-title,
            .k-datetime-wrap .k-time-header .k-title {
                color: #FFF;
            }

            .k-time-highlight {
                background-color: transparent;
                border-color: #dee2e6;
            }

            .k-time-list-wrapper {
                background-color: transparent;
            }

            .k-content {
                border-color: #dee2e6;
                color: #FFF;
                background-color: transparent;
            }
        }

        .k-timeselector .k-time-list-wrapper .k-title,
        .k-datetime-wrap .k-time-list-wrapper .k-title {
            color: #FFF;
            background-color: rgb(45, 44, 44);
            background: rgb(45, 44, 44);
            opacity: 1;
        }

        .k-timeselector .k-time-list-wrapper.k-state-focused .k-title,
        .k-datetime-wrap .k-time-list-wrapper.k-state-focused .k-title {
            background-color: rgb(32, 31, 31);
            background: rgb(32, 31, 31);
            color: rgba(255, 255, 255, 0.87);
            opacity: 1;
        }

        .k-time-list-wrapper.k-state-focused::before,
        .k-time-list-wrapper.k-state-focused::after {
            background-color: rgba(32, 31, 31, 0.87);
        }

        .k-timeselector .k-time-list-wrapper .k-time-list .k-item:hover,
        .k-datetime-wrap .k-time-list-wrapper .k-time-list .k-item:hover {
            border-color: rgba(32, 31, 31, 0.87);
            color: rgba(255, 255, 255, 0.87);
            background-color: rgba(32, 31, 31, 1);
        }

        .k-time-header .k-time-now:hover,
        .k-time-header .k-time-now:focus {
            color: rgba(70, 71, 117, 1);
        }

        .k-timeselector .k-time-header .k-time-now:hover,
        .k-timeselector .k-time-header .k-time-now:focus,
        .k-datetime-wrap .k-time-header .k-time-now:hover,
        .k-datetime-wrap .k-time-header .k-time-now:focus {
            color: rgba(70, 71, 117, 1);
        }
    }


    .k-actions .k-button:not(.k-primary),
    .k-actions .k-button-secondary,
    .k-action-buttons .k-button:not(.k-primary),
    .k-action-buttons .k-button-secondary,
    .k-columnmenu-actions .k-button:not(.k-primary),
    .k-columnmenu-actions .k-button-secondary {
        border-color: rgb(96, 94, 92);
        box-shadow: rgb(0 0 0 / 25%) 0px 0.2rem 0.4rem -0.075rem;
        background-color: rgb(45, 44, 44);
        color: #fff;
    }

    .k-actions .k-button.k-primary,
    .k-actions .k-button-primary,
    .k-action-buttons .k-button.k-primary,
    .k-action-buttons .k-button-primary,
    .k-columnmenu-actions .k-button.k-primary,
    .k-columnmenu-actions .k-button-primary {
        border-color: transparent;
        box-shadow: rgb(0 0 0 / 25%) 0px 0.2rem 0.4rem -0.075rem;
        background-color: rgb(98, 100, 167);
        color: #fff;
    }

    .k-action-buttons .k-button:hover,
    .k-action-buttons .k-button.k-state-hover {
        border-color: rgb(138, 136, 134);
        background-color: rgb(59, 58, 57);
    }

    .k-button.k-primary:hover {
        background-color: rgb(139, 140, 199);
    }

    .k-button:hover,
    .k-button:focus {
        text-decoration: none;
        outline: 0;
    }

    .ui-dropdown__selecteditem__icon {

        border-left: solid 1px rgba(255, 255, 255, 0.12);
    }
}

body.contrast {

    .ui-checkbox[aria-checked="true"][aria-disabled="true"]>.ui-checkbox__indicator,
    .ui-checkbox[aria-checked="true"][aria-disabled="true"]>.ui-checkbox__indicator::before {
        border-color: rgb(26, 235, 255);
    }

    .timePicker input[type="time"],
    .datePicker input[type="date"] {
        &::-webkit-calendar-picker-indicator {
            filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
        }
    }

    .k-grid-pager {
        border-color: rgba(25, 102, 110, 0.7);
        color: #252423;
        background-color: #1aebff;

        .k-pager-refresh,
        .k-pager-nav.k-link,
        .k-pager-numbers .k-link {
            border-color: rgba(0, 0, 0, 0.15);
            color: #FFF;
            background-color: rgba(0, 0, 0, 0.15);
        }

        .k-pager-nav.k-link:hover,
        .k-pager-refresh:hover,
        .k-state-hover.k-pager-nav.k-link,
        .k-state-hover.k-pager-refresh,
        .k-pager-numbers .k-link:hover,
        .k-pager-numbers .k-link.k-state-hover,
        .k-pager-numbers .k-link.k-state-selected {
            border-color: rgba(0, 0, 0, 0.35);
            /* color: #FFF; */
            background-color: rgba(0, 0, 0, 0.35);
        }

    }

    .k-dateinput .k-dateinput-wrap,
    .k-datepicker .k-dateinput-wrap,
    .k-datetimepicker .k-dateinput-wrap,
    .k-timepicker .k-dateinput-wrap {
        border: 1px solid #fff;
        border-top-color: #fff !important;
        border-radius: 0.0714rem;
    }

    .k-dateinput .k-dateinput-wrap .k-input,
    .k-dateinput .k-picker-wrap .k-input,
    .k-datepicker .k-dateinput-wrap .k-input,
    .k-datepicker .k-picker-wrap .k-input,
    .k-datetimepicker .k-dateinput-wrap .k-input,
    .k-datetimepicker .k-picker-wrap .k-input,
    .k-timepicker .k-dateinput-wrap .k-input,
    .k-timepicker .k-picker-wrap .k-input {
        color: #FFF;
        background: rgb(0, 0, 0);
    }

    .k-datepicker .k-picker-wrap .k-select,
    .k-timepicker .k-picker-wrap .k-select {
        box-shadow: none;
        border-color: #FFF;
        background-color: rgb(0, 0, 0);
        color: #FFF;
    }

    .k-datepicker .k-picker-wrap:hover .k-select,
    .k-datepicker .k-picker-wrap.k-state-hover .k-select,
    .k-timepicker .k-picker-wrap:hover .k-select,
    .k-timepicker .k-picker-wrap.k-state-hover .k-select,
    .k-datetimepicker .k-picker-wrap:hover .k-select,
    .k-datetimepicker .k-picker-wrap.k-state-hover .k-select {
        color: rgb(0, 0, 0);
        background-color: rgb(26, 235, 255);

        .k-icon::before {
            color: rgb(0, 0, 0);
        }
    }

    .k-popup {
        border-color: #fff;
        color: #FFF;
        background-color: rgb(0, 0, 0);
        box-shadow: rgb(0 0 0 / 10%) 0px 0.8rem 1rem -0.2rem;

        .k-calendar {
            border-color: transparent;
            color: #fff;
            background-color: transparent;

            .k-footer .k-nav-today,
            .k-calendar-header .k-today {
                color: rgb(255, 255, 1);

                &:hover {
                    color: rgb(255, 255, 1);
                }
            }

            .k-content .k-today,
            .k-calendar-content .k-today {
                color: #FFF;

                .k-link {
                    box-shadow: inset 0 0 0 1px rgb(26, 235, 255);
                }
            }

            .k-calendar-navigation li:hover {
                color: rgb(255, 255, 1);
            }

            .k-state-selected .k-link {
                border-color: rgb(26, 235, 255);
                color: rgb(0, 0, 0);
                background-color: rgb(26, 235, 255);
            }

            .k-state-hover .k-link,
            td:hover .k-link,
            .k-calendar-td:hover .k-link {
                border-color: rgb(255, 255, 1);
                color: rgb(0, 0, 0);
                background-color: rgb(255, 255, 1);
            }

            .k-calendar-navigation-highlight {
                border-color: #dee2e6;
                color: #FFF;
                background-color: rgb(45, 44, 44);
            }

            .k-calendar-navigation {
                color: #FFF;
                background-color: transparent;
                box-shadow: inset -1px 0 #dee2e6;
            }

            .k-content {
                border-color: #dee2e6;
                color: #FFF;
                background-color: transparent;
            }

            .k-content th,
            .k-calendar-content th,
            .k-content .k-calendar-th,
            .k-calendar-content .k-calendar-th {
                color: rgba(255, 255, 255, 0.87);
            }
        }

        .k-timeselector {
            .k-time-header .k-time-now {
                color: rgb(255, 255, 1);
                outline: none;
            }

            .k-time-header .k-title,
            .k-datetime-wrap .k-time-header .k-title {
                color: #FFF;
            }

            .k-time-highlight {
                background-color: transparent;
                border-color: #1aebff;
            }

            .k-time-list-wrapper {
                background-color: transparent;
            }

            .k-content {
                border-color: #dee2e6;
                color: #FFF;
                background-color: transparent;
            }
        }

        .k-timeselector .k-time-list-wrapper .k-title,
        .k-datetime-wrap .k-time-list-wrapper .k-title {
            color: #FFF;
            background-color: rgb(45, 44, 44);
            background: rgb(45, 44, 44);
            opacity: 1;
        }

        .k-timeselector .k-time-list-wrapper.k-state-focused .k-title,
        .k-datetime-wrap .k-time-list-wrapper.k-state-focused .k-title {
            background-color: rgb(32, 31, 31);
            background: rgb(32, 31, 31);
            color: rgba(255, 255, 255, 0.87);
            opacity: 1;
        }

        .k-time-list-wrapper.k-state-focused::before,
        .k-time-list-wrapper.k-state-focused::after {
            background-color: rgba(32, 31, 31, 0.87);
        }

        .k-timeselector .k-time-list-wrapper .k-time-list .k-item:hover,
        .k-datetime-wrap .k-time-list-wrapper .k-time-list .k-item:hover {
            border-color: transparent;
            color: rgb(255, 255, 1);
            background-color: transparent;
        }

        .k-time-header .k-time-now:hover,
        .k-time-header .k-time-now:focus {
            color: rgb(255, 255, 1);
        }

        .k-timeselector .k-time-header .k-time-now:hover,
        .k-timeselector .k-time-header .k-time-now:focus,
        .k-datetime-wrap .k-time-header .k-time-now:hover,
        .k-datetime-wrap .k-time-header .k-time-now:focus {
            color: rgb(255, 255, 1);
        }
    }


    .k-actions .k-button:not(.k-primary),
    .k-actions .k-button-secondary,
    .k-action-buttons .k-button:not(.k-primary),
    .k-action-buttons .k-button-secondary,
    .k-columnmenu-actions .k-button:not(.k-primary),
    .k-columnmenu-actions .k-button-secondary {
        border-color: #fff;
        box-shadow: none;
        background-color: rgb(0, 0, 0);
        color: #fff;
    }

    .k-actions .k-button.k-primary,
    .k-actions .k-button-primary,
    .k-action-buttons .k-button.k-primary,
    .k-action-buttons .k-button-primary,
    .k-columnmenu-actions .k-button.k-primary,
    .k-columnmenu-actions .k-button-primary {
        border-color: transparent;
        box-shadow: none;
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
    }

    .k-action-buttons .k-button:hover,
    .k-action-buttons .k-button.k-state-hover {
        border-color: rgb(26, 235, 255);
        background-color: rgb(26, 235, 255);
    }

    .k-button.k-primary:hover {
        background-color: rgb(26, 235, 255);
    }

    .k-button:hover,
    .k-button:focus {
        text-decoration: none;
        outline: 0;
    }
}

body.default {
    background: #fff;

    &.inTeams {
        background: #f3f2f1;
    }

    .ui-provider {
        background: transparent;
    }

    .ui-card.dashboardCard {
        border-bottom-color: #edebe9;
        background-color: transparent;

        &:hover {
            &:not(:first-child) {
                background-color: rgb(70, 71, 117) !important;
                color: #fff;
            }

            .btnFormAction svg {
                fill: #fff;
            }

            &:first-child {
                background-color: transparent !important;
            }
        }
    }

    .ui-card {
        background-color: #fff;

        .ui-button.btnDanger {
            color: #fff;
            border: 1px solid rgb(157, 39, 60);
            background-color: rgb(157, 39, 60);

            &:hover {
                background-color: rgb(196, 49, 75);
            }
        }

        &:not(.dashboardCard) {
            border: none;
        }

        &:hover {
            // background: #F4F4FC !important;
        }

        >.ui-card__header {
            //border-bottom-color:rgb(237, 235, 233);
            font-size: .95rem;
            background: rgb(70, 71, 117);
            color: #fff;
            border: 1px solid rgb(70, 71, 117);
            font-weight: 400;

            .iy {
                fill: rgba(255, 255, 255, .85);
            }

            .ui-button:not(.nonprimary):not(:disabled) svg {
                fill: #fff;
            }
        }

        >.ui-card__body {
            border: 1px solid rgba(0, 0, 0, 0.12);
        }
    }

    .ui-card:not(:hover) {
        // background-color: #fff;
        // box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }

    .ui-card:hover .k-chart-surface>svg>g:first-of-type>path:first-of-type {
        // fill: #F4F4FC;
    }

    .k-grid-header {
        background-color: rgba(0, 0, 0, .035);
    }

    .k-grid-pager {
        color: #252423;
        background-color: rgba(0, 0, 0, 0.035);
        border-color: rgba(0, 0, 0, 0.035);
    }

    // .k-grid th.k-header a{
    //   color: #fff;
    // }
    .k-grid-content {
        background-color: transparent;
    }

    // changes selected chart legends desired color 
    // .k-chart-surface > svg > g:first-of-type text {
    //     fill: #252423;
    // }
}

.meetingHabit .k-card {
    box-shadow: none;
    border-radius: 0px !important;
}

body.dark {
    background-color: rgb(45, 44, 44);

    .ui-provider {
        background-color: transparent;
    }

    .bg-white {
        background-color: #292828 !important;
    }

    .ui-card {
        box-shadow: 0 3px 1px -2px rgb(59, 58, 57), 0 2px 2px 0 rgb(59, 58, 57), 0 1px 5px 0 rgb(59, 58, 57) !important;
        background: #292828;

        &:hover {

            background: #232323;
        }

        >.ui-card__header {
            border-bottom-color: rgb(59, 58, 57);
        }

        .ui-card__body {
            background: #292828;

            &:hover {

                background: #232323;
            }
        }
    }

    .ui-dropdown__item.kb {
        background-color: #2f2f4a !important;
    }

    .k-chart-surface>svg>g:first-of-type>path:first-of-type {
        fill: rgb(41, 40, 40);
    }

    .ui-card:hover .k-chart-surface>svg>g:first-of-type>path:first-of-type {
        fill: rgb(59, 58, 57);
    }

    // changes selected chart legends desired color 
    .k-chart-surface>svg>g:first-of-type text {
        fill: #fff;
    }

    .k-chart-surface path[stroke-width="1"] {
        stroke: #535353;
    }

    .k-progressbar {
        background-color: #474644;
    }

    .k-progressbar .k-state-selected {
        background-color: #fff;
    }

    .k-grid-header {
        border-color: rgba(0, 0, 0, 0.14);
        color: #fff;
        background-color: rgba(59, 58, 57, 1);
    }

    .k-grid th.k-header a {
        color: #fff;
    }

    .k-grid-content {
        background-color: transparent;
    }

    .k-grid td,
    .k-grid .k-grid-filter,
    .k-grid .k-header-column-menu,
    .k-grid .k-hierarchy-cell .k-icon {
        color: #fff;
    }

    .k-window {
        border-color: rgba(0, 0, 0, 0.14);
        color: #fff;
        background-color: rgba(59, 58, 57, 1);
    }

    .k-combobox .k-dropdown-wrap .k-input {
        color: #fff;
    }

    .k-form .k-form-field,
    .k-form .k-form-field-wrap {
        color: #fff;
    }

    .k-form fieldset legend,
    .k-icon {
        color: #fff;
    }

    .k-list-scroller {
        background: #aaa;
        color: white;
    }


    .contact-list-icon {
        border-color: #484644;
    }
}

body.contrast {
    background-color: rgb(0, 0, 0);

    .ui-provider {
        background-color: transparent;
    }

    .ui-card {
        >.ui-card__header {
            // border-bottom-color: rgb(26, 235, 255);
            border-bottom-color: #fff;
        }

        &:not(.dashboardCard) {
            color: #ffff01;
            border: 1px solid #fff;
            box-shadow: 0 3px 1px -2px rgb(59, 58, 57), 0 2px 2px 0 rgb(59, 58, 57), 0 1px 5px 0 rgb(59, 58, 57) !important;

            .ui-button:not(:disabled) svg {
                fill: #ffff01;
            }
        }

        &.dashboardCard {
            border-bottom-color: #fff;
        }
    }

    .k-chart-surface>svg>g:first-of-type>path:first-of-type {
        fill: transparent;
    }

    .ui-card:hover {
        color: #252423;

        >.ui-card__header {
            border-bottom-color: rgb(26, 235, 255);
        }

        .k-chart-surface>svg>g:first-of-type>path:first-of-type {
            fill: rgb(26, 235, 255);
        }

        .k-grid td {
            color: #252423;
            border-color: rgba(25, 102, 110, 0.7);
        }

        .k-chart-surface path[stroke-width="1"] {
            stroke: rgba(25, 102, 110, 0.5);
        }

        .k-chart-surface>svg>g:first-of-type text {
            fill: #252423;
        }

        .contact-list-icon {
            // border-color: #13b3c2;
            border-color: rgba(37, 36, 35, 0.7);
        }

        .activity-view .topUsrArea {
            border-left-color: rgba(19, 179, 194, 0.5);
        }

        &:not(.dashboardCard) {
            .ui-button:not(:disabled) svg {
                fill: #252423;
            }
        }


        .mainLabel-p svg {
            fill: #888;
        }
    }


    .k-chart-surface>svg>g:first-of-type text {
        fill: #ffff01;
    }

    .k-chart-surface>svg>g:first-of-type>g:nth-child(6) text {
        fill: #fff;
    }

    .k-chart-surface path[stroke-width="1"] {
        stroke: rgba(255, 255, 1, 0.15);
    }

    .k-progressbar {
        background-color: #474644;
    }

    .k-progressbar .k-state-selected {
        background-color: #fff;
    }

    .k-grid-header {
        border-color: rgba(25, 102, 110, 0.7);
        color: #fff;
        background-color: rgb(26, 235, 255);
    }

    .k-grid th.k-header a {
        color: #252423;
    }

    .k-grid-content {
        background-color: transparent;
    }

    .k-grid tr {
        border-color: rgba(25, 102, 110, 0.7);
    }

    .k-grid td {
        color: #ffff01;
        border-color: rgba(255, 255, 1, 0.3);
    }

    .k-grid .k-grid-filter,
    .k-grid .k-header-column-menu,
    .k-grid .k-hierarchy-cell .k-icon {
        color: #ffff01;
    }

    .k-window {
        border-color: rgba(0, 0, 0, 0.14);
        color: #fff;
        background-color: rgba(59, 58, 57, 1);
    }

    .k-combobox .k-dropdown-wrap .k-input {
        color: #fff;
    }

    .k-form .k-form-field,
    .k-form .k-form-field-wrap {
        color: #fff;
    }

    .k-form fieldset legend,
    .k-icon {
        color: #fff;
    }

    .k-list-scroller {
        background: #aaa;
        color: white;
    }

    .k-list .k-item.k-state-selected,
    .k-list-optionlabel.k-state-selected {
        color: #fff;
        background-color: rgb(26, 235, 255) !important;
    }


    .contact-list-icon {
        border-color: rgba(255, 255, 1, 0.5);
    }

    .activity-view .topUsrArea {
        border-left-color: rgba(255, 255, 1, 0.2);
    }
}

.k-grid {
    border-color: transparent;
    background-color: transparent;
}

.k-grid-header {
    width: 100%;
    padding: 0 !important;
}

.ui-card {
    //padding: 2px 6px !important;
    border-radius: 0;
    width: 100%;

    >.ui-card__header {
        padding: 6px 2px 7px 10px;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        margin-bottom: 0;

        .ui-text:first-of-type {
            font-size: 0.95rem;
            font-weight: 400;
        }
    }

    >.ui-card__body {
        margin-bottom: 0;
        height: calc(100% - 43.8px);
        overflow: auto;
        padding: 1px;
    }
}

.name-cell {
    color: $text-base;
}

.multiline-header-cell {
    &>small {
        display: block;
        font-weight: 300;
        font-size: 11px;
    }
}

.k-form fieldset legend {
    font-size: 100%;
}


.cardCustomizeDialog>.ms-Dialog-main {
    /* width: 100%; */
    max-width: 80%;
}

.k-grid .k-grid-filter,
.k-grid .k-header-column-menu,
.k-grid .k-hierarchy-cell .k-icon {
    opacity: 0.54;
}

.categorylist.ui-list:not(.editCard_list) {
    @include lt-sm {
        display: inline-flex;
    }

    .ui-list__item__media {
        padding-top: 0;

        @include lt-sm {
            padding-top: 5px;
            margin-right: 0;
        }
    }

    li.ui-list__item {
        width: 100%;

        @include lt-sm {
            flex-direction: column;
            padding: 4px 0;
            min-height: 2.5rem;

            .ui-list__item__header {
                text-align: center;
                font-size: 9px;
                margin-right: 0;
            }
        }
    }
}

.categorylist.ui-list.editCard_list {
    @include lt-sm {
        display: block;

        .ui-list__item__main {
            justify-content: center;

            .ui-list__item__header {
                font-size: unset;
            }
        }
    }
}

.ui-dropdown__items-list {
    max-height: 180px !important;

    @include lt-sm {
        max-height: 120px !important;
    }
}

.ui-dropdown__selecteditem {
    cursor: default !important;
    font-size: .8em !important;
    line-height: 1 !important;
    padding: .25rem .5rem !important;
    // height: auto !important; (increases risk of appearence of scrolls in dropdown)
    height: fit-content !important;


    &._expandable:before {
        cursor: pointer;
        content: '+';
        padding-right: 5px;
        font-size: 14px;
        font-weight: 600;
    }

    &._unauthorized {

        pointer-events: none;

        //background-color: rgb(242, 227, 132);
        //color: rgb(131, 92, 0);
    }

    &._unauthorized>div {
        display: none;
        //background-color: rgb(242, 227, 132);
        //color: rgb(131, 92, 0);
    }
}

.ui-dropdown__selected-items {

    // Dropdown toggle button fix
    span {
        padding-right: 1rem !important;
    }

    padding-right: .5rem !important;
}


.ui-dropdown__selecteditem__icon {
    cursor: pointer;
    width: 14px !important;
    height: 14px !important;
    padding-left: 2px;
    border-left: solid 1px rgba(0, 0, 0, 0.12);
    color: palevioletred !important;
}

.k-window-title {
    font-size: 1.15rem;
}

.ui-checkbox[aria-checked="true"][aria-disabled="true"]>.ui-checkbox__indicator,
.ui-checkbox[aria-checked="true"][aria-disabled="true"]>.ui-checkbox__indicator::before {
    border-width: 1px;
    border-style: solid;
    border-color: rgb(88, 90, 150);
}

// code for cards

// .ki {
//     background-color: transparent !important;
//     &:hover {
//         background-color: transparent !important;
//     }
// }

.react-grid-item.cssTransforms.react-resizable-hide.react-resizable {

    // border-radius: 1em !important;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px, rgba(0, 0, 0, 0.1) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    }
}

body.default .ui-card>.ui-card__header {
    // border-radius: 1em 1em 0 0 !important;
}

body.default .ui-card>.ui-card__body {
    // border-radius: 0 0 1em 1em !important;
    background-color: #fff !important;
}

// .pe {
//     background-color: transparent !important;
//     &:hover {
//         background-color: transparent !important;
//     }
// }


// add card header alignment issue
.k-grid-content.k-virtual-content {
    overflow-y: overlay !important;
    overflow: auto;
}