@import '../../../styles/media-queries.scss';


.bg-edebe9{
    background-color: #edebe9;
}
.edit_user_container {
    overflow: auto;

    @include lt-sm {
        min-width: unset !important;
        width: 100%;
    }
}

.user-profile-header {
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    grid-gap: 10px;
    justify-content: unset !important;

    @include lt-sm {
        //grid-template-rows: 1fr 1fr;
    }
}

.no-data-icon {
    @include lt-sm {
        margin-top: 1em !important;
    }
}

.ui-provider {
    >.ui-tooltip__content {
        z-index: 99999999;
    }

    >.ui-popup__content {
        z-index: 999999;
    }
}

.subscriptionDropDown {
    >.ui-dropdown {
        >.ui-dropdown__container {
            >.ui-dropdown__selected-items {
                max-width: 130px;
                min-width: 130px;
            }
        }
    }
}

.k-grid {
    >.k-grid-container {
        // min-height: 250px;
    }
}


.k-dialog-wrapper {
    z-index: 2247483647 !important;
}

.k-detail-row {
    >.k-detail-cell {
        overflow: visible;
    }
}

/*
.k-grid {
    > .k-grid-container{
        overflow: visible !important;

        > .k-grid-content{
            overflow: visible !important;
        }
    }
}
*/

.ui-dropdown__selecteditem {
    &._disabledItem {
        background-color: rgb(211, 211, 211);
        color: #999;
    }
}

.ui-dropdown__item {
    &._disabledItem {
        >.ui-dropdown__item__main {
            >.ui-dropdown__item__header {
                color: rgb(170, 170, 170);
            }
        }
    }
}

.ui-dropdown__item {
    &._licenseUnavailable {
        >.ui-dropdown__item__main {
            >.ui-dropdown__item__header {
                color: rgb(170, 170, 170);
            }

            >.ui-dropdown__item__content {
                color: red;
            }
        }
    }
}


.profile-link {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.user-profile {
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    grid-gap: 10px;

    >.ui-segment {
        &.focused {
            border: 2px solid #ccc;
        }
    }

    @include lt-sm {
        grid-template-columns: 100% !important;
        grid-template-rows: auto 1fr;

        .user-profile-managerlisting {
            height: 100% !important;
            max-height: 330px;
        }

        .user-profile-groupslisting {
            height: 100% !important;
            max-height: 330px;
        }
    }
}

.user-info {
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    align-items: flex-start;
    -webkit-box-align: flex-start;
    -moz-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-align-items: flex-start;
}

.whRow {
    @include lt-sm {
        grid-template-columns: 100% !important;
    }

    .ui-dropdown__container {
        max-width: 100%;
    }
}

.timePicker,
.datePicker {
    >.ui-box {
        width: 100%;

        input.ui-box {
            width: 100%;
            padding: 0.3571rem 2.5rem 0.3571rem 0.8571rem;

            &::-webkit-calendar-picker-indicator {
                font-size: 16px;
                cursor: pointer;
            }

            &:not(:required) {
                padding: 0.3571rem 0.8571rem;
            }
        }
    }
}

.editBtnGroup {
    display: flex;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;

    @include lt-sm {
        >button.ui-button {
            min-width: auto;
            padding: 8px 12px;

            >span.ui-button__content {
                font-size: 0.8em;
            }
        }
    }
}


.textOverFlow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    text-align: start;
}

.leftSideBar {
    min-width: 200px;
}

.rightSideBar {
    max-width: calc(100% - 200px);

    @include lt-sm {
        max-width: 100%;
    }
}

.permissions-group {
    >.ui-grid {
        >*:first-child {
            text-align: left;
        }

        >*:last-child {
            text-align: left;
        }

        &:not(.whRow) {
            grid-template-columns: 140px 180px calc(100% - 330px) !important;
        }
    }

    @include lt-sm {
        .ui-dropdown__items-list {
            width: 100% !important;
            transform: translate(0, 33.3333px) !important;
        }
    }
}

.edit_user_officeHoursForm {
    @include lt-sm {
        >.ui-grid:not(.whRow) {
            position: relative;
            padding-top: 24px;
            grid-template-columns: calc(50% - 16px) 16px calc(50% - 16px) !important;

            >span.ui-text:first-of-type {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .ui-dropdown__items-list {
            width: 100% !important;
            transform: translate(0, 33.3333px) !important;
        }
    }
}

.chkTrial-mobile {
    padding-right: 0 !important;
    grid-template-columns: 100% !important;
}

.usersconfigGrid>.k-grid-container {
    min-height: 212px;
}


//user config category list icon alignment fix
.categorylist {
    .ui-list__itemmedia {
        line-height: 1 !important;
    }
}

//config category section
.category-mobile {
    li {
        &:not(:hover) {
            background-color: var(--light) !important;
            color: var(--secondary) !important;
        }

        margin: 2px !important;
        padding: 4px !important;
        border-radius: 4px;

        .ui-list__itemheader {
            margin-right: 0 !important;
            text-align: center !important;
            font-size: 11px;
            line-height: 1.2;
        }

        .ui-list__itemmedia {
            margin-right: 0 !important;
        }

        .ui-list__item__headerWrapper {
            margin-top: auto !important;
            margin-bottom: auto !important;
        }
    }
}

// scroll fix config -> edit

.h-0 {
    height: auto !important;
}

.filterLblRow-txt {
    font-size: 13px;
}

.filterLbl:not(.cst) .ui-dropdown__selected-items button .ui-button__content {
    font-size: 13px;
    color: #666;
    font-weight: 400;
}

.filterLbl .ui-dropdown .ui-dropdown__container {
    border: none !important;
}


        // .filterLbl .ui-dropdown__container {
        //     background: #fff;
        //     border: 1px solid rgba(0, 0, 0, .3);
        // }


.filterLbl .ui-dropdown__selected-items button {
    height: 22px;
}

.card-bdr-top {
    border-top: 1px solid rgba(0, 0, 0, .07) !important;
}

.filterLblRow-icn {
    color: rgb(136, 136, 136);
}

.filterLblRow-btn .ui-text {
    font-size: 13px;
    font-weight: 400;
    color: #666;
    padding: 0 2px 0 0;
}

.filterLblRow-btn .ui-flex svg {
    opacity: .6;
}


//userconfig.js_380
.w142fixSm {
    .ui-dropdown__container {
        width: 142px !important;
    }
}

.question-category-cell{
    display: block;
}

.question-count-label{
    position: absolute;
    top: 12px;
    right: 8px;
}


//teamvibeconfig.js
//teamvibeconfig-grids

.teamvibe-grid {
    thead{
        tr{
            th.k-header{
                &[aria-colindex="5"]{
                    text-align: center !important;
                }
            }
        }
    }
}


.category-checks {
    display: grid;
    gap: 24px;
    //grid-template-columns: 1fr;
     grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    box-shadow: none; // rgba(0, 0, 0, 0.18) 0px 2px 4px;
    transition: all ease-in 100ms;
    .ui-checkbox{
        span{
            font-weight: 600;
        }
    }

    .chk-card {
        label{
            font-size: 1rem;
            font-weight: 500;
        }
        // &:last-child{
        //     border-bottom: none !important;
        // }
    }
}

.vibe-set-start {
    display: grid;
    place-items: center;
    height: calc(100% - 100px);
}

.vibe-get-start {
    padding: 48px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-radius: 4px;
    text-align: center;
    transition: all ease-in-out 500ms;
    &:hover{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
        transition: all ease-in-out 500ms;
    }
}

.bg-danger-del {
    color: red;
    font-size: 1.1rem;
    background: transparent;
    transition: all 200ms ease-in-out;
     &:hover{
        color: #dc3545;
        transition: all 200ms ease-in-out;
     }
     &:active{
        color: #dc354689;
        transition: all 200ms ease-in-out;
     }
}

span.chk-sub-header {
    font-size: 0.75rem;
    opacity: .8;
}

.chk-card {
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 8px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}
.configurator-grid-holder{
    display: flex;
    margin-bottom: 24px;
    flex-direction: column;
    align-items: end;
}

.response-type-card {
    .ui-radiogroup__item{
        margin: 4px !important;
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        transition: box-shadow 200ms ease-out;
        width: 100%;
        padding: 8px;
        margin: 0;

        &[aria-checked="true"]{
            box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px,0 0 0 4px rgb(91, 95, 199);
            transition: box-shadow 200ms ease-in-out;
            cursor: default;
        }
        &:hover{
            &:not([aria-checked="true"]){
                box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px,0 0 0 4px rgba(91, 95, 199,.5);
            transition: box-shadow 200ms ease-in;
            
            }
        }
        .ui-radiogroup__item__indicator{
            display: none !important;
        }
        .ui-radiogroup__item__label{
            margin: 0;
            width: 100%;
        }
    }
}

.questionEditForm{
    .response-type{
        grid-gap: 10px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);

        > div{
            width: 100%;
        }
    }
}

.response-type{
    justify-content: space-between;
    .response-emoji{
        border: none !important;
        font-size: 24px;
    }
    .response-label:not(.type2){
        font-size: 10px;
    }
    .type2{
        font-size: 12px;
    }
}

//communication profile
.cp-grid-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
    gap: 24px;
    margin: .625rem;
}

.lt-sm-p-4px{
    @include lt-sm{
        padding: 4px !important;
    }
}

.flex-r-b-p{
    bottom: 0;
    right: 16px;
}