.timeline-container {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    padding: 6px 0;
}

.timeline-row-container {
    display: grid;
    gap: 0px;
    background-color: #fafafa;
    max-height: 280px;
    overflow: auto;


    .timeline-row {
        display: grid;

        &:first-child {
            position: sticky;
            top: 0;
            z-index: 5; /* Ensures it stays above other elements */
        }

        .sticky-cell {
            position: sticky;
            left: 0;
            z-index: 3;
        }

        .corner-cell {
            background-color: #fafafa;
            border: 1px dashed #ebebeb;
            text-align: center;
            padding: 5px;
            font-weight: 600;
            z-index: 5 !important
        }

        .header-cell {
            background-color: #fafafa;
            text-align: center;
            font-weight: 400;
            padding: 5px;
            border: 1px dashed #ebebeb;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .date-cell, .time-cell {
            /*display:block;*/
        }

        .time-slot {
            background-color: #fff;
            height: 36px;
            border: 1px dashed #ebebeb;
            position: relative;
            padding: 2px 0;
        }
    }
}







.activity-block {
    position: absolute;
    height: 30px;
    left: 0;
    right: 0;
    color: #fff;
    /*padding: 0 5px;*/
    border-radius: 0.4rem;
    z-index: 1;
    transition: transform 300ms ease-in-out;
}

.activity-block-bubble {
    bottom: 2px;
    position: absolute;
    border-radius: 50%;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 12px;
    z-index: 1;
    transition: transform 300ms ease-in-out;
    background-color: rgba(54, 162, 235, 0.6);

    &:hover {
        transform: scale(1.25);
        background-color: rgba(54, 162, 235, 1);
    }
}

.activity-block.call {
    background-color: rgba(39,174,96, 0.6);

    &:hover {
        background-color: rgba(39,174,96, 1);
    }
}

.activity-block.meeting {
    display: flex;

    .normal-duration {
        background: rgba(187, 107, 217, 0.5);
        border-radius: 0.4rem 0 0 0.4rem;

        &.b-smooth {
            border-radius: 0.4rem 0.4rem 0.4rem 0.4rem;
        }
    }

    .overrun-duration {
        background: repeating-linear-gradient( 45deg, rgba(187, 107, 217, 0.5), rgba(187, 107, 217, 0.5) 4px, rgba(255, 255, 255, 0.7) 4px, rgba(255, 255, 255, 0.7) 8px);
        border-radius: 0 0.4rem 0.4rem 0;
    }

    &:hover {
        .normal-duration {
            background: rgba(187,107,217, 1);
        }

        .overrun-duration {
            background: repeating-linear-gradient( 45deg, rgba(187, 107, 217, 1), rgba(187, 107, 217, 1) 4px, rgba(255, 255, 255, 1) 4px, rgba(255, 255, 255, 1) 8px);
        }
    }
}

.activity-block:hover {
    z-index: 2; /* Highlight the block being hovered */
    transform:scaleY(1.25);
}


.timeline-legend {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 20px;
    row-gap: 6px;
    justify-content: center;
    font-size: 14px;
}

.timeline-legend-item {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    &.unselected {
        color: #bebebe !important;

        .color-indicator {
            background-color: #bebebe !important;

            &.meeting-overrun {
                background: repeating-linear-gradient( 45deg, rgba(190, 190, 190, 1), rgba(190, 190, 190, 1) 4px, rgba(255, 255, 255, 1) 4px, rgba(255, 255, 255, 1) 8px) !important;
            }
        }
    }

    .color-indicator {
        width: 32px;
        height: 10px;
        border-radius: 0.4rem;

        &.call {
            background-color: green;
        }

        &.meeting {
            background-color: rgb(187, 107, 217);
        }

        &.meeting-overrun {
            background: repeating-linear-gradient( 45deg, rgba(187, 107, 217, 1), rgba(187, 107, 217, 1) 4px, rgba(255, 255, 255, 1) 4px, rgba(255, 255, 255, 1) 8px);
        }

        .label {
            font-weight: bold;
        }

        .value {
            color: #555;
        }
    }
}





