
@import "../../../../styles/media-queries.scss";

body.dark {
    .hsc-progress {
        .progress-label {
            label {
                color: darken($color: #fff, $amount: 15%);
            }
        }

        .wrapper {
            .progress-label {
                label {
                    color: darken($color: #fff, $amount: 15%);
                }
            }

            .progress-main {
                .couple-prog-gradient {
                    .prog-bg {
                        background-color: #4e4e4e;
                    }

                    .insight-prog.danger {
                        background-color: #F75E75 !important;
                    }

                    .insight-prog.success {
                        background-color: #00ff88 !important;
                    }

                    .insight-prog.warning {
                        background-color: #F9EC02 !important;
                    }

                    .insight-prog:not(:last-child) {
                        border-right: solid 1px #4e4e4e;
                    }

                    .vLine {
                        border-left: solid #000 1px;
                    }

                    .p1 {
                        background: linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%);
                    }

                    .p2 {
                        background: linear-gradient(90deg, #3F5EFB 0%, #00ff88 100%);
                    }

                    .danger-zone-l {
                        background: linear-gradient(90deg, #FC466B 0%, #fb9a3f 100%);
                    }

                    .warning-zone-l {
                        background: linear-gradient(90deg, #fb9a3f 0%, #FFD700 50%, #d4ff00 100%);
                    }

                    .success-zone {
                        background: linear-gradient(90deg, #d4ff00 0%, #00ff88 50%, #d4ff00 100%);
                    }

                    .warning-zone-r {
                        background: linear-gradient(90deg, #d4ff00 0%, #FFD700 50%, #fb9a3f 100%);
                    }

                    .danger-zone-r {
                        background: linear-gradient(90deg, #fb9a3f 0%, #FC466B 100%);
                    }

                    .p1.productive-hours {
                        background: linear-gradient(90deg, #FC466B 0%,#FFD700 30%, #00ff88 100%);
                    }

                    .p2.productive-hours {
                        background: linear-gradient(90deg,#00ff88 0%, #FFD700 20%, #FC466B 50%);
                    }

                    .p1.meetingAttendance {
                        background: linear-gradient(90deg, #FC466B 0%,#FFD700 50%, #00ff88 100%);
                    }

                    .p2.meetingAttendance {
                        background: linear-gradient(90deg, #00ff88 0%, #008552 100%);
                    }
                }

                .progress-set-mark {
                    .vL {
                        background-color: black;
                        box-shadow: 0px 0px 0px 1px white;
                    }

                    .cL {
                        box-shadow: 0 0 0 2px white, rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
                    }
                }

                .progress {
                    background-color: #cecece;
                }
            }

            .progress-value {
                label {
                    color: darken($color: #fff, $amount: 15%);
                }
            }
        }
    }
}

body.contrast {
    .hsc-progress {
        .progress-label {
            label {
                color: #ffff00;
            }
        }

        .wrapper {
            .progress-label {
                label {
                    color: #ffff00;
                }
            }

            .progress-main {
                .couple-prog-gradient {
                    .prog-bg {
                        background-color: #4e4e4e;
                    }

                    .insight-prog.danger {
                        background-color: #F75E75 !important;
                    }

                    .insight-prog.success {
                        background-color: #00ff88 !important;
                    }

                    .insight-prog.warning {
                        background-color: #F9EC02 !important;
                    }

                    .insight-prog:not(:last-child) {
                        border-right: solid 1px #4e4e4e;
                    }

                    .vLine {
                        border-left: solid #000 1px;
                    }

                    .p1 {
                        background: linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%);
                    }

                    .p2 {
                        background: linear-gradient(90deg, #3F5EFB 0%, #00ff88 100%);
                    }

                    .danger-zone-l {
                        background: linear-gradient(90deg, #FC466B 0%, #fb9a3f 100%);
                    }

                    .warning-zone-l {
                        background: linear-gradient(90deg, #fb9a3f 0%, #FFD700 50%, #d4ff00 100%);
                    }

                    .success-zone {
                        background: linear-gradient(90deg, #d4ff00 0%, #00ff88 50%, #d4ff00 100%);
                    }

                    .warning-zone-r {
                        background: linear-gradient(90deg, #d4ff00 0%, #FFD700 50%, #fb9a3f 100%);
                    }

                    .danger-zone-r {
                        background: linear-gradient(90deg, #fb9a3f 0%, #FC466B 100%);
                    }

                    .p1.productive-hours {
                        background: linear-gradient(90deg, #FC466B 0%,#FFD700 30%, #00ff88 100%);
                    }

                    .p2.productive-hours {
                        background: linear-gradient(90deg,#00ff88 0%, #FFD700 20%, #FC466B 50%);
                    }

                    .p1.meetingAttendance {
                        background: linear-gradient(90deg, #FC466B 0%,#FFD700 50%, #00ff88 100%);
                    }

                    .p2.meetingAttendance {
                        background: linear-gradient(90deg, #00ff88 0%, #008552 100%);
                    }
                }

                .progress-set-mark {
                    .vL {
                        background-color: black;
                        box-shadow: 0px 0px 0px 1px white;
                    }

                    .cL {
                        box-shadow: 0 0 0 2px white, rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
                    }
                }

                .progress {
                    background-color: #ffff00;
                }
            }

            .progress-value {
                label {
                    color: #ffff00;
                }
            }
        }
    }
}

.hsc-progress {
    @include gt-xxs {
        display: grid;
    }
    // padding: 4px;
    margin-bottom: 4px;
    grid-template-columns: 70px 1fr;
    align-items: center;

    .progress-label {
        label {
            margin-bottom: 2px;
            font-size: 12px;
            font-weight: 700;
            color: #4f4f4f;
            width: 60px;
        }
    }

    .wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        .progress-label {
            label {
                margin-bottom: 2px;
                font-size: 12px;
                font-weight: 700;
                color: #4f4f4f;
                width: 60px;
            }
        }

        .progress-main {
            position: relative;
            width: 100%;

            .couple-prog-gradient {
                position: absolute;
                display: flex;
                width: 100%;

                .prog-bg {
                    width: 100%;
                    background-color: #d2d2d2;
                }

                .insight-prog.danger {
                    background-color: #dc3545 !important;
                }

                .insight-prog.success {
                    background-color: #28a745 !important;
                }

                .insight-prog.warning {
                    background-color: #ffc107 !important;
                }

                .insight-prog:not(:last-child) {
                    border-right: solid 1px white;
                }

                .vLine {
                    z-index: 100;
                    margin: -2px 1px;
                    border-left: solid #000 1px;
                }

                .p1 {
                    background: linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%);
                }

                .p2 {
                    background: linear-gradient(90deg, #3F5EFB 0%, #00ff88 100%);
                }

                .danger-zone-l {
                    background: linear-gradient(90deg, #FC466B 0%, #fb9a3f 100%);
                }

                .warning-zone-l {
                    background: linear-gradient(90deg, #fb9a3f 0%, #FFD700 50%, #d4ff00 100%);
                }

                .success-zone {
                    background: linear-gradient(90deg, #d4ff00 0%, #00ff88 50%, #d4ff00 100%);
                }

                .warning-zone-r {
                    background: linear-gradient(90deg, #d4ff00 0%, #FFD700 50%, #fb9a3f 100%);
                }

                .danger-zone-r {
                    background: linear-gradient(90deg, #fb9a3f 0%, #FC466B 100%);
                }

                .p1.productive-hours {
                    background: linear-gradient(90deg, #FC466B 0%,#FFD700 30%, #00ff88 100%);
                }

                .p2.productive-hours {
                    background: linear-gradient(90deg,#00ff88 0%, #FFD700 20%, #FC466B 50%);
                }

                .p1.meetingAttendance {
                    background: linear-gradient(90deg, #FC466B 0%,#FFD700 50%, #00ff88 100%);
                }

                .p2.meetingAttendance {
                    background: linear-gradient(90deg, #00ff88 0%, #008552 100%);
                }
            }

            .progress-set-mark {
                .vL {
                    background-color: black;
                    box-shadow: 0px 0px 0px 1px white;
                    width: 1px;
                }

                .cL {
                    //box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
                    border: solid 2px;
                    width: 6px;
                    box-shadow: 0 0 0 2px white, rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
                }

                position: absolute;
                transform: translate(-50%,0%);
                font-size: 10px;
                z-index: 100;
            }

            .progress {
                position: absolute;
                background-color: #cecece;
                height: 100%;
                border-radius: 0 !important;
            }
        }

        .progress-value {
            @media screen and (max-width: 799px) {
                line-height: 1;
            }

            text-align: end;
            width: 36px;

            label {
                margin-bottom: 2px;
                color: #4f4f4f;
                font-size: 12px;
                font-weight: 700;
            }
        }
    }
}
