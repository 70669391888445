.multi-segment-slider{    
    margin: 1rem 0px 2rem 0px;

    &.view-only{
        margin: 1rem 0 1rem 0;

        .zone-slider-container{
            margin: 18px 0 8px 0;

            .slider-mark-label{
                // display: none;
            }
            span.slider-mark-handle::before,
            span.slider-mark-handle::after{
                height: 4px;
            }

            .slider-input{
                display: none;
            }
        }
    }
&.compact{    
    margin: 0 0 4px 0;
    padding: 4px;
    display: grid;
    align-items: center;
    grid-gap: 10px;
    grid-template-columns: 60px 1fr 32px;

    .slider-display-value{
        margin-bottom: 2px;
    color: #4f4f4f;
    font-size: 12px;
    font-weight: 700;
    min-width: 36px;
    }
    
    .zone-slider-container{
        margin: 0rem 0;

        .slider-mark{
            display: none;
        }
        .slider-input{
            width: 2px;
    height: 10px;
    top: 1px;
    border-left: 1px solid #000;
    border-color: #000;
    /* margin: 0px 2px; */
    border-radius: 0;
    background: #FFF;
        }
        .slider-value{
            display: none;
        }
    }
}

.slider-header{
    min-width: 60px;
    font-size: 12px;
    font-weight: 700;
    color: #4f4f4f;
}
    .zone-slider-container {
        &.read-only{
            height: 6px;
        }
        position: relative;
        width: 100%;
        min-width: 250px;
        height: 8px;
        display: flex;
         margin: 24px 0;
        //margin: 2.5rem 0;
        border-radius: 5px;
        background: #ddd;
        transition: height 0.3s ease;

        .slider-section:not(:nth-child(5)){
            border-right: solid 1px white;
        }

        &.focused {
            &:not(.read-only) {
                height: 10px;
            }
        }
        
        &:not(.read-only) {
            .slider-input,
            .slider-mark-handle,
            .slider-mark{
                cursor: pointer;
            }
        }
        .slider-value {
            position: absolute;
            font-size: 14px;
            left: 50%;
            transform: translate(-50%, 100%);
        }
    
        .slider-input:hover,
        .slider-input.focused {
            &:not(.read-only) {
                width: 16px;
                height: 24px;
            }
        }
    
        .slider-input {
            transition: width 0.3s ease, height 0.3s ease;
            position: absolute;
            height: 18px;
            width: 12px;
            background: #fff;
            border: 1px solid #ccc;
            top: -2px;
            border-radius: 4px;
            transform: translate(-50%, -25%);

            span.drag-indicator {
                border-top: 1px solid #CCC;
                border-bottom: 1px solid #CCC;
                height: 45%;
                width: 60%;
                top: 3px;
                position: absolute;
                transform: translate(25%, 25%);

                > span {
                    border-top: 1px solid #CCC;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    transform: translateY(50%);
                }
            }
        }
    
        span.slider-mark-handle::before {
            display: none;
            content: '';
            height: 10px;
            position: absolute;
            left: 50%;
            top: 0%;
            border-left: 1px solid #949292;
            transform: translate(-50%, -75%);
        }
    
        .slider-mark-container {
            &.focused:not(.read-only),
            &:hover:not(.read-only) {
                .triangle-container {
                    width: 0;
                    height: 0;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-bottom: 12px solid #424242;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, 4px);
                }
            }
    
        }
    
        .slider-mark-container {
            &.focused:not(.read-only),
            &:hover:not(.read-only) {
    
                .slider-mark-label,
                span.slider-mark-handle::before,
                span.slider-mark-handle::after {
                    border-color: #424242;
                    color: #424242;
                }
            }
        }
    
    
        span.slider-mark-handle::after {
             display: none;
            /* width: 1px; */
            content: '';
            height: 10px;
            position: absolute;
            left: 50%;
            top: 50%;
            border-left: 1px solid #949292;
            transform: translate(-50%, 10%);
        }
    
    
        .slider-section {
            height: 100%;
            transition: width 0.0s;
        }
        &:not(.read-only){
            .slider-mark-handle {
                position: absolute;
                width: 15px;
                height: 15px;
                transform: translate(50%, -20%);
                z-index: 1;
                border-color: #1274AC;
                border-radius: 50%;
            }
        }
        .slider-mark-handle {
            position: absolute;
            width: 15px;
            height: 15px;
            transform: translate(50%, -6%);
            z-index: 1;
            border-color: #1274AC;
            border-radius: 50%;
        }
    
        .slider-mark-container {
            position: relative;
            color: #949292;
            height: 22px;
            //height: 30px;
            width: 30px;
        }
        &:not(.read-only){
            .slider-mark-label {
                position: absolute;
                font-size: 13px;
                top: -100%;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .slider-mark-label {
            position: absolute;
            font-size: 10px;
            top: -64%;
            left: 50%;
            transform: translateX(-50%);
        }
    
        .slider-mark {
            position: absolute;
            top: 0;
            bottom: 0;
            transform: translateX(-50%);
            z-index: 1;
        }
    }
}

